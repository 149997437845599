export enum StationState {
  okay,
  notOkay,
  missing,
}

export enum AdminView {
  stations,
  specialEvents,
  damages,
}
